import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { aboutUsData } from '../constant/config'

const About = () => {
    return (
        <>
            <div className='mt-20 bg-black'>
                <div className='grid xl:grid-cols-2 grid-cols-1 '>
                    <div className='flex items-center text-white py-20 lg:px-16 px-10'>
                        <div>
                            <div>
                                <p className=' font-medium text-[1.12rem]'># About Us</p>
                                <Fade direction='up' triggerOnce='true'>
                                    <p className='lg:text-7xl text-5xl font-medium mt-5 leading-tight'>We focus on the Vision and Mission.</p>
                                </Fade>
                            </div>
                            <div className='mt-16 space-y-6'>
                                <Fade direction='up' triggerOnce='true'>
                                    <p className='text-xl leading-8'>
                                        With blockchain integration, World 11 offers secure transactions, from purchasing entry tickets to exchanging tokens and rewards. This next-generation technology ensures that every action on our platform is transparent, immutable, and protected, making World 11 a safe and trusted environment for players of all levels. We prioritize global accessibility, ensuring that no matter where users are located, they can participate in their favorite sports, connect with fans worldwide, and enjoy a seamless experience across devices.
                                    </p>
                                </Fade>
                            </div>
                        </div>
                    </div>

                    <div className="sports-image-cross-cut lg:mt-0 mt-10">
                        <img
                            src='/assets/images/about-us-bg.jpg'
                            alt=''
                            className='object-cover h-full w-full'
                        />
                    </div>
                </div>
            </div>
            <div className='text-xl leading-8 mt-20 lg:w-[65vw] w-full lg:px-16 px-10'>
                <Fade direction='up' triggerOnce='true'>
                    <p >At the heart of World 11 is the ambition to innovate and elevate the fantasy sports experience for players around the globe. We believe that fantasy sports should be more than just entertainment—it should be an immersive experience that brings fans closer to the action. Our platform is designed to leverage cutting-edge technology, ensuring users have the best tools at their disposal to compete and win.</p>
                </Fade>
                <Fade direction='up' triggerOnce='true'>
                    <p className='mt-8'>
                        User engagement is central to our mission, and we are continuously improving features to ensure that players have real-time updates, player insights, and access to major sporting events. By offering a decentralized fantasy sports ecosystem, we empower users not just as participants, but as stakeholders in the ever-growing world of sports. At World 11, our goal is to inspire fans to compete, engage, and take their fantasy sports journey to new heights.
                    </p>
                </Fade>
            </div>


            <div className='grid xl:grid-cols-2 grid-cols-1 lg:px-16 px-10 mt-20'>
                <div className=' md:text-5xl text-3xl font-medium header-lineHeight flex justify-center'>
                    <Fade direction='up' triggerOnce='true'>
                        <p>Building a Global Community for Fantasy Sports Enthusiasts.</p>
                    </Fade>
                </div>
                <div>
                    {aboutUsData.map((item, index) => {
                        return <Fade direction='up' triggerOnce='true'>
                            <div className='xl:mb-10 mb-0 xl:mt-0 mt-10' key={index}>
                                <p className='lg:text-3xl text-2xl font-medium'>{item.name}</p>
                                <p className='mt-2 lg:text-lg text-base'>{item.description}</p>
                            </div>
                        </Fade>
                    })}
                </div>

            </div>

            <div className='grid lg:grid-cols-8 grid-cols-1 md:gap-16 gap-3 mt-20 lg:px-16 px-10'>
                <div className='lg:col-span-3 col-span-1'>
                    <Fade direction='right' triggerOnce='true' delay={500}>
                        <img src="/assets/images/football-player.png" alt="right-content" className='w-full' />
                    </Fade>
                </div>
                <div className='col-span-5'>
                    <Fade direction='right' triggerOnce='true'>
                        <img src="/assets/images/cricket-player.jpg" alt="left-content" className='h-full w-full' />
                    </Fade>
                </div>
            </div>
        </>
    )
}

export default About
