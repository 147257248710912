import React, { useContext, useState, useEffect } from 'react'
import { NavbarContext, navItems } from '../constant/config'
import { facebookIcon, twitterIcon, youtubeIcon, instagramIcon } from '../constant/icon'
import { Fade } from "react-awesome-reveal";

const Navbar = () => {

    const { isOpen, setIsOpen } = useContext(NavbarContext);
    const [isActive, setIsActive] = useState(1);
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    // Function to handle scroll event
    const handleScroll = () => {
        const currentScrollY = window.scrollY;

        if (currentScrollY > lastScrollY) {
            // Scrolling down
            setIsNavbarVisible(false);
        } else {
            // Scrolling up
            setIsNavbarVisible(true);
        }
        setLastScrollY(currentScrollY);
    };

    useEffect(() => {
        // Add scroll event listener when component mounts
        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
        //eslint-disable-next-line
    }, [lastScrollY]);


    useEffect(() => {
        // if (!isMobileView()) return;
        const handleScroll = () => {
            let currentId = 1;
            navItems.forEach((item) => {
                const section = document.getElementById(item.href.substr(1));
                if (section) {
                    const { top, bottom } = section.getBoundingClientRect();
                    if (top <= 50 && bottom >= 50) {
                        currentId = item.id;
                    }
                }
            });
            setIsActive(currentId);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line
    }, [navItems]);

    const handleMenuItemClick = (event, id) => {
        event.preventDefault();
        const targetId = event.target.getAttribute('href').substr(1);
        const targetElement = document.getElementById(targetId);
        targetElement.scrollIntoView({ behavior: 'smooth' });
        setIsOpen(false);
        setIsActive(id)
    };

    const handleMenuClick = () => {
        // window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth' // This makes the scroll smooth
        // });
        setIsOpen(!isOpen)
    };

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        // Cleanup when component unmounts
        return () => {
            document.body.classList.remove('no-scroll');
        };
        // eslint-disable-next-line
    }, [isOpen]);


    return (
        <div>
            <nav className={`${window.pageYOffset < window.innerHeight ? "absolute" : 'fixed'} bg-black w-full z-50  top-0 ${isNavbarVisible ? "translate-y-0" : "-translate-y-full"
                } duration-300`}>
                <div className="mx-auto">
                    <div className="flex justify-between">
                        <div className="flex items-center">
                            <a href="/" className="text-3xl font-normal text-white flex items-center  px-4 sm:px-6 lg:px-8">
                                <img src="/assets/images/eleven-logo.png" alt="logo" className='w-52' />
                            </a>
                        </div>
                        <div className="flex items-center h-full">
                            <div className="header relative "></div>
                            {isOpen ? (
                                <div className='h-full image-cross-cut'>
                                    <button onClick={() => setIsOpen(!isOpen)} className=' bg-customGreen h-full p-8 w-[190px] ps-20'># Close</button>
                                </div>
                            ) : (
                                <div className='h-full image-cross-cut'>
                                    <button onClick={() => handleMenuClick()} className=' bg-customGreen h-full p-8  w-[190px] ps-20'># Menu</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='md:block hidden'>
                    {
                        isOpen && (
                            <Fade direction='up' triggerOnce="true">
                                <div className={`lg:absolute lg:right-0 w-full text-white lg:p-4 lg:z-50 bg-black min-h-max h-[calc(100vh-85.6094px)] flex items-center `} >
                                    <div className='w-full h-full'>
                                        <div className="px-6 py-4 text-lg h-full">
                                            <div className="grid grid-cols-12 gap-4 h-full  items-center ">
                                                <div className="col-span-3">
                                                    <a href='https://www.facebook.com/' target='_blank' rel="noreferrer" className='cursor-pointer my-2 w-12 h-12 border border-customGray flex justify-center items-center'>

                                                        <i className={`${facebookIcon} text-xl`} />
                                                    </a>
                                                    <a href='https://www.instagram.com/' target='_blank' rel="noreferrer" className='cursor-pointer my-2 w-12 h-12 border border-customGray flex justify-center items-center'>

                                                        <i className={`${instagramIcon} text-xl  `} />
                                                    </a>
                                                    <a href='https://www.youtube.com/' target='_blank' rel="noreferrer" className='cursor-pointer my-2 w-12 h-12 border border-customGray flex justify-center items-center'>

                                                        <i className={`${youtubeIcon} text-xl  `} />
                                                    </a>
                                                    <a href='https://x.com/' target='_blank' rel="noreferrer" className='cursor-pointer my-2 w-12 h-12 border border-customGray flex justify-center items-center'>

                                                        <i className={`${twitterIcon} text-xl  `} />
                                                    </a>

                                                </div>
                                                <div className="col-span-9 h-full overflow-scroll flex flex-col 2xl:justify-center md:justify-start">
                                                    {navItems.map((item, index) => (
                                                        <div className='w-96' key={index}>
                                                            <a href={item.href} className={`nav-link block ${isActive === item.id ? "text-customGreen" : ""}   hover:text-customGreen pb-2 pt-3 font-bold text-5xl text-[#999999]`} onClick={(e) => handleMenuItemClick(e, item.id)}>
                                                                {item.label}
                                                            </a>
                                                        </div>
                                                    ))}
                                                    {/* <div className='h-[68px]'></div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        )
                    }
                </div>
                <div className='md:hidden block overflow-scroll'>
                    {
                        isOpen && (
                            // <Fade direction='up'>
                            <div className={`lg:absolute lg:right-0 w-full text-white lg:p-4 block lg:block lg:z-50 bg-black h-screen `} >
                                <div className="px-6 py-4 text-lg h-full overflow-y-scroll">
                                    <div className="h-[800px] overflow-y-scroll">

                                        <div className="">
                                            {navItems.map((item, index) => (
                                                <div key={index}>
                                                    <a href={item.href} className={`nav-link block ${isActive === item.id ? "active" : ""}   hover:text-customGreen pb-3 pt-3 font-bold text-3xl text-[#999999]`} onClick={(e) => handleMenuItemClick(e, item.id)}>
                                                        {item.label}
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="flex mt-[10px]">
                                            <a href='https://www.facebook.com/' target='_blank' rel="noreferrer" className='cursor-pointer my-2 w-12 h-12 border border-customGray flex justify-center items-center me-3'>

                                                <i className={`${facebookIcon} text-xl`} />
                                            </a>
                                            <a href='https://www.instagram.com/' target='_blank' rel="noreferrer" className='cursor-pointer my-2 w-12 h-12 border border-customGray flex justify-center items-center mx-3'>

                                                <i className={`${instagramIcon} text-xl  `} />
                                            </a>
                                            <a href='https://www.youtube.com/' target='_blank' rel="noreferrer" className='cursor-pointer my-2 w-12 h-12 border border-customGray flex justify-center items-center mx-3'>

                                                <i className={`${youtubeIcon} text-xl  `} />
                                            </a>
                                            <a href='https://x.com/' target='_blank' rel="noreferrer" className='cursor-pointer my-2 w-12 h-12 border border-customGray flex justify-center items-center mx-3'>

                                                <i className={`${twitterIcon} text-xl  `} />
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            // </Fade>
                        )
                    }
                </div>

            </nav >
        </div >
    )
}

export default Navbar
