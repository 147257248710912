import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { howItWorksData } from '../constant/config'

const HowItWork = () => {

    return (
        <>
            <div className="bg-[url('/public/assets/images/howitworks-bg.png')] bg-no-repeat h-full bg-cover mt-20">
                <div className='lg:px-16 px-10'>
                    <div className='grid xl:grid-cols-2'>
                        <div>
                            <Fade direction='up' triggerOnce='true' >
                                <div className=' font-medium text-[1.12rem] lg:pt-28 pt-20'># How It Works</div>
                            </Fade>
                            <Fade direction='up' triggerOnce='true' >
                                <div className='mt-6  md:text-5xl text-3xl font-medium header-lineHeight'>Create your fantasy team, complete in global leagues, and earn World 11 Tokens.</div>
                            </Fade>
                        </div>
                    </div>
                    <div className='grid xl:grid-cols-11 lg:pb-28 pb-20 col-span-10'>
                        <div className='lg:col-span-4 '></div>
                        <div className='grid md:grid-cols-2 grid-cols-1 lg:gap-20 gap-10 mt-20 xl:col-span-6 col-span-11'>
                            {howItWorksData.map((item, index) => (
                                <Fade key={index} direction='up' triggerOnce='true'>
                                    <div>
                                        <p className='lg:text-3xl text-2xl font-medium'>{item.title}</p>
                                        <p className='mt-2 lg:text-lg text-base'>{item.description}</p>
                                    </div>
                                </Fade>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HowItWork
