import React, { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { sportsData } from '../constant/config';

const Sports = () => {
    const [currentSport, setCurrentSport] = useState(sportsData[0].name);

    const handleMouseEnter = (sport) => {
        setCurrentSport(sport);
    };

    return (
        <div className='lg:mt-0 mt-20 lg:h-[75vh] lg:min-h-[80vh]'>
            <div className='grid lg:grid-cols-2 grid-cols-1 h-full'>
                <div className='flex items-center'>
                    <div className='lg:px-16 px-10'>
                        <p className='font-medium text-[1.12rem] text-black mb-10'># Sports</p>

                        {sportsData.map((sport, index) => (
                            <Fade direction='up' triggerOnce='true' key={index}>
                                <div className='mt-7'>
                                    <p
                                        className={`md:text-4xl text-2xl font-medium cursor-pointer 
                                            ${currentSport === sport.name ? 'text-customPink' : ''} 
                                            hover:text-customPink`} // Apply hover and active styles
                                        onMouseEnter={() => handleMouseEnter(sport.name)} // Only hover effect
                                    >
                                        {sport.name}
                                    </p>
                                </div>
                            </Fade>
                        ))}
                    </div>
                </div>

                <div className="sports-image-cross-cut relative lg:mt-0 mt-10 h-full">
                    {sportsData.map(
                        (sport, index) =>
                            currentSport === sport.name && (
                                <div key={index} className='relative h-full min-h-[30vh]'>
                                    <img
                                        src={sport.image}
                                        alt={sport.alt}
                                        className='w-full h-full object-cover'
                                    />
                                    <div className='absolute inset-0 flex items-end bg-black bg-opacity-50  md:ps-16 ps-20 pe-3  md:pb-0 pb-3'>
                                        <p className='text-white sm:text-lg text-sm md:p-4'>{sport.description}</p>
                                    </div>
                                </div>
                            )
                    )}
                </div>
            </div>
        </div>
    );
};

export default Sports;
