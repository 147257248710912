import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { homeData } from '../constant/config'

const Home = () => {

    return (
        <div>
            <div className="overflow-hidden relative h-screen top-0" >
                <video
                    src="/assets/video/loading-screen.mp4"
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="absolute inset-0 w-full h-full object-cover bg-black" />
                <div className="absolute flex px-2 justify-start items-end text-white text-2xl h-screen">
                    <div className='xl:ms-10 ms-5 lg:mb-14 mb-24'>
                        <div className='pe-5 mt-5'>
                            <p className='text-customGreen text-lg font-medium'># Sports</p>
                            <Fade direction='up' triggerOnce='true'>
                                <p className=' text-white font-medium lg:text-8xl md:text-5xl text-4xl mt-5 text-shadow'>Welcome to World 11</p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div >
            {/* <div className='lg:px-16 px-10 mt-40'>
                <div>
                    <Fade direction='up' triggerOnce='true'>
                        <p className='md:text-5xl text-3xl font-medium lg:w-[80vw] w-full header-lineHeight' >Whether you’re playing for fun or aiming to be a top-ranked fantasy manager, World 11 brings the excitement of real-life sports into a unique virtual arena.</p>
                    </Fade>
                </div>
                <div className='text-xl leading-8 mt-24 lg:w-[65vw] w-full'>
                    <Fade direction='up' triggerOnce='true'>
                        <p className=''>World 11 stands at the cutting edge of fantasy sports, revolutionizing the industry by integrating blockchain technology for secure, transparent, and fast transactions. As a global fantasy platform, World 11 offers users access to a diverse range of sports, including cricket, football, basketball, rugby, and esports. World 11 redefines how sports fans engage with their favorite teams and players. By merging traditional fantasy gameplay with next-generation blockchain-powered features. World 11 redefines how sports fans engage with their favorite teams and players, offering global access, top-tier competition, and exclusive digital rewards.</p>
                    </Fade>
                    <Fade direction='up' triggerOnce='true'>
                        <p className='mt-8'>
                            With competitive leagues spanning across countries, users can create their dream teams, track real-time performances, and compete for exclusive prizes. The intuitive interface ensures ease of use, allowing users to effortlessly create teams, engage in tournaments, and monitor their standings, making it an engaging experience for both beginners and seasoned fantasy sports enthusiasts.
                        </p>
                    </Fade>
                </div>
            </div> */}

            <div className="mt-28">
                <div className='lg:px-16 px-10'>
                    <div className='grid xl:grid-cols-2'>
                        <div>
                            <Fade direction='up' triggerOnce='true' >
                                <div className='mt-6  md:text-5xl text-3xl font-medium header-lineHeight'>Join a blockchain fantasy sports platform for global leagues and rewards.</div>
                            </Fade>
                        </div>
                    </div>
                    <div className='grid xl:grid-cols-11 col-span-10'>
                        <div className='lg:col-span-4 '></div>
                        <div className='grid md:grid-cols-2 grid-cols-1 lg:gap-20 gap-10 mt-20 xl:col-span-6 col-span-11'>
                            {homeData.map((item, index) => (
                                <Fade key={index} direction='up' triggerOnce='true'>
                                    <div>
                                        <p className='lg:text-3xl text-2xl font-medium'>{item.title}</p>
                                        <p className='mt-2 lg:text-lg text-base'>{item.description}</p>
                                    </div>
                                </Fade>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='grid lg:grid-cols-8 grid-cols-1 md:gap-16 gap-3 lg:px-16 px-10 mt-20'>
                <div className='col-span-5 '>
                    <Fade direction='left' triggerOnce='true'>
                        <img src="/assets/images/home-content.png" alt="left-content" className="h-full w-full" />
                    </Fade>
                </div>
                <div className='lg:col-span-3 col-span-1'>
                    <Fade direction='left' triggerOnce='true' delay={500}>
                        <img src="/assets/images/home-content-1.jpg" alt="right-content" className='w-full' />
                    </Fade>
                </div>
            </div>
        </div >
    )
}

export default Home
