import React from 'react'
import { Zoom } from 'react-awesome-reveal'
import { blogData } from '../constant/config'

const Blog = () => {
    return (
        <div className='container mx-auto'>
            <div className='lg:block hidden '>
                <div className='xl:mx-16 mx-10 grid grid-cols-8 pt-16'>
                    <div className='col-span-2 sticky top-0 bg-white xl:h-[100vh]'>
                        <p className='text-[#084AFF] text-lg font-medium mt-14'># Blog & News</p>
                    </div>
                    <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 xl:gap-44 gap-14 mt-20 lg:mx-0 md:mx-10 mx-3 xl:col-span-6 col-span-8">
                        {blogData.map((card, index) => (
                            <Zoom triggerOnce={true} key={index}>
                                <div className={`h-full ${index % 2 === 0 ? 'md:translate-y-14' : 'md:-translate-y-14'}`}>
                                    {/* <div className="bg-white  h-full "> */}
                                    <div className="blogCard">
                                        <img src={card.imageUrl} alt={card.title} className=' w-full ' />
                                    </div>
                                    <div className="mt-5">
                                        <h5 className="mb-1 text-2xl font-medium  text-black">{card.title}</h5>
                                        <h5 className="mb-1 text-lg  text-gray-900">{card.description}</h5>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </Zoom>
                        ))}
                    </div>
                </div>
            </div>
            <div className='lg:hidden block mx-4'>
                <div>
                    <p className='text-[#084AFF] text-lg font-normal mt-14 px-5'># Blog & News</p>
                </div>

                <div className='grid md:grid-cols-2 grid-cols-1'>
                    {blogData.map((card, index) => (
                        <Zoom triggerOnce={true} key={index}>
                            <div className={`h-full mt-10 px-5`}>
                                {/* <div className="bg-white h-full"> */}
                                <div className="blogCard">
                                    <img src={card.imageUrl} alt={card.title} className='w-full' />
                                </div>
                                <div className='mt-5'>
                                    <h5 className="mb-1 text-2xl font-medium  text-black">{card.title}</h5>
                                    <h5 className="mb-1 text-lg  text-gray-900">{card.description}</h5>
                                </div>
                                {/* </div> */}
                            </div>
                        </Zoom>
                    ))}
                </div>
            </div>

        </div>
    )
}

export default Blog
