import React from 'react';
import { world11TokenData } from '../constant/config';

const World11Token = () => {

    return (
        <div className='bg-black lg:py-28 py-20'>
            <div className='lg:px-16 px-10'>
                <p className=' font-medium text-[1.12rem] text-white'># World 11 Tokens</p>
                <div className='mt-6 md:text-5xl text-3xl font-medium leading-tight text-white'>  Earn and redeem digital assets that reward your fantasy sports skills.</div>
            </div>
            <div className="container mx-auto mt-16">
                <div className='flex flex-wrap justify-center'>
                    {world11TokenData.map((token, index) => {
                        return (
                            <div className="card relative group w-1/2 p-2" key={index}>
                                <img src={token.image} alt="token" className="h-full w-full" />
                                <h3 className="z-10 text-4xl font-medium text-white transition-transform duration-300 absolute bottom-14 group-hover:hidden">
                                    {token.name}
                                </h3>

                                <div className="info relative">
                                    <h3 className="z-10 text-4xl font-medium text-black transition-transform duration-300">
                                        {token.name}
                                    </h3>
                                    <p className='text-lg text-black'>{token.description}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}
export default World11Token
