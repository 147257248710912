import { useState } from 'react';
import './App.css';
import About from './components/About';
import Blog from './components/Blog';
import Footer from './components/Footer';
import Home from './components/Home';
import HowItWork from './components/HowItWork';
import Navbar from './components/Navbar';
import Partnership from './components/Partnership';
import ScrollButton from './components/ScrollButton';
import Sports from './components/Sports';
import Tournaments from './components/Tournaments';
import Wolrd11Token from './components/Wolrd11Token';
import { NavbarContext } from './constant/config';

function App() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <NavbarContext.Provider value={{ isOpen, setIsOpen }}>
      <div >
        <Navbar />
        <div >
          <section className={`section`} id="home-section">
            <Home />
          </section>

          <section className={`section`} id="about">
            <About />
          </section>

          <section className={`section`} id="how-it-works">
            <HowItWork />
          </section>

          <section className={`section`} id="partnership">
            <Partnership />
          </section>

          <section className={`section`} id="sports">
            <Sports />
          </section>

          <section className={`section`} id="world-11-token">
            <Wolrd11Token />
          </section>

          <section className={`section`} id="tournaments">
            <Tournaments />
          </section>

          <section className={`section`} id="blog-news">
            <Blog />
          </section>

          <section className={`section`} id="contact-us">
            <Footer />
          </section>
        </div>
        <ScrollButton />
      </div>
    </NavbarContext.Provider>

  );
}

export default App;
