import { createContext } from "react";

export const navItems = [
    { id: 1, href: "#home-section", label: "Home" },
    { id: 2, href: "#about", label: "About Us" },
    { id: 3, href: "#how-it-works", label: "How It Works" },
    { id: 4, href: "#partnership", label: "Partnerships" },
    { id: 5, href: "#sports", label: "Sports" },
    { id: 6, href: "#world-11-token", label: "World 11 Token" },
    { id: 7, href: "#tournaments", label: "Tournaments" },
    { id: 8, href: "#blog-news", label: "Blog/News" },
    { id: 9, href: "#contact-us", label: "Contact Us" },
    // { href: "#help-support", label: "Help & Support" },
    // { href: "#design-structure", label: "Design & Structure" },
];

export const homeData = [
    {
        id: 1,
        title: "# Blockchain Integration",
        description: "Our platform leverages blockchain technology for secure, fast transactions, ensuring a seamless fantasy sports experience with the World 11 token."
    },
    {
        id: 2,
        title: "# Global Access",
        description: " Users can participate in a diverse range of fantasy sports, including cricket, football, basketball, rugby, and esports, from anywhere in the world."
    },
    {
        id: 3,
        title: "# Competitive Leagues Across Sports",
        description: "Players from different regions can join leagues and tournaments, competing globally and enhancing the thrill of fantasy sports."
    },
    {
        id: 4,
        title: "# Easy Team Creation and Monitoring",
        description: "With an intuitive interface, creating fantasy teams and tracking real-time performance is straightforward, making it easy to stay engaged and informed."
    },
    {
        id: 5,
        title: "# Win Prizes",
        description: "Users have the chance to win exciting rewards, including tokens, cash prizes, and exclusive digital collectibles, encouraging participation and competition."
    }
];

export const aboutUsData = [
    {
        id: 1,
        name: '# Mission Statement',
        description: 'Our mission is to become the leading global platform for fantasy sports, fostering innovation and inclusivity for all sports enthusiasts.'
    },
    {
        id: 2,
        name: '# Innovation Through Technology',
        description: 'We utilize blockchain technology for secure transactions, transparent scoring, and robust user data protection, enhancing the fantasy sports experience.'
    },
    {
        id: 3,
        name: '# Fan Engagement',
        description: 'World 11 promotes fan engagement by allowing users to create personalized fantasy teams and connect with their favorite sports and athletes.'
    },
    {
        id: 4,
        name: '# Global Connectivity',
        description: "We are committed to connecting sports fans worldwide, breaking geographical barriers to create a vibrant and inclusive community."
    }
]

export const sportsData = [
    {
        name: 'Cricket',
        description: 'Fantasy points in cricket are based on runs, wickets, catches, and strike rates, with leagues like IPL and international matches available. Strategic picks include all-rounders and players suited to match conditions.',
        image: '/assets/images/cricket.png',
        alt: 'Cricket'
    },
    {
        name: 'Football',
        description: 'Points in football are awarded for goals, assists, and clean sheets. Leagues like the Premier League and UEFA are featured, with balanced team selections key to maximizing points.',
        image: '/assets/images/football.png',
        alt: 'Football'
    },
    {
        name: 'Basketball',
        description: 'Basketball fantasy points are based on stats like points scored, rebounds, assists, steals, and blocks. Users can join leagues like the NBA and should focus on versatile players for maximum impact.',
        image: '/assets/images/basketball.png',
        alt: 'Basketball'
    },
    {
        name: 'eSports',
        description: 'Fantasy eSports leagues cover games like League of Legends and Dota 2, with points for kills, assists, and wins. Real-time performance is used to track players in fast-paced competitions.',
        image: '/assets/images/esport.jpg',
        alt: 'eSports'
    },
    {
        name: 'Rugby',
        description: 'Rugby fantasy leagues feature tournaments like the Rugby World Cup. Points are based on tries, tackles, conversions, and penalties, with a focus on high-impact players.',
        image: '/assets/images/rugby.jpeg',
        alt: 'Rugby'
    }
];

export const howItWorksData = [
    {
        title: '# Signing Up',
        description: "Getting started is easy! Users can sign up by registering with their email or by using a social media login. Once registered, you'll gain full access to the platform's features, including the latest sports news, fantasy tips, and tournament insights.",
    },
    {
        title: '# Creating Teams',
        description: "Users can draft their fantasy teams across multiple sports, such as cricket, football, and basketball. Each team can be created by selecting real-world players, and points are calculated based on the actual performance of these players in live matches.",
    },
    {
        title: '# Joining Leagues',
        description: 'You can join leagues to compete with friends or participate in global tournaments. Whether you want to join a public league or create a private league for your group, you can easily enter the competition. The platform offers various league types.',
    },
    {
        title: '# Earning Rewards',
        description: 'By winning tournaments or completing platform challenges, users can accumulate World 11 Tokens. These tokens can be used to enhance your fantasy sports experience, enter premium leagues, or trade for exclusive collectibles.',
    },
    {
        title: '# Fantasy Point System',
        description: 'Points are awarded based on the real-world performance of the players you choose. Each sport has its own scoring metrics. In cricket, points are earned for wickets, runs, and catches. Detailed scoring rules for each sport will guide you in making strategic team selections to maximize your points.',
    },
];

export const partnershipData = [
    {
        title: '# Sports Organizations',
        description: 'World 11 is proud to partner with renowned sports leagues, teams, and governing bodies to deliver an authentic fantasy sports experience. These collaborations ensure that users get real-time access to official player stats, match updates, and tournament information.',
    },
    {
        title: '# Athlete Collaborations',
        description: 'Our platform partners with top athletes to offer exclusive fantasy leagues, player tips, and unique content, giving users direct insights from their favorite sports stars and enhancing their fantasy gaming experience.',
    },
    {
        title: '# Blockchain Companies',
        description: 'World 11 has formed alliances with leading blockchain providers to ensure secure, fast, and transparent transactions on the platform. The use of blockchain technology guarantees that all token-related transactions are immutable and secure.',
    },
    {
        title: '# Business and Corporate Sponsorships',
        description: "World 11 invites corporations and investors to join the platform as partners, sponsors, or advertisers. With a growing user base of passionate sports fans, there are unique opportunities for brands to engage with an active community. ",
    },
];

export const world11TokenData = [
    {
        name: 'What are World 11 Tokens?',
        description: 'World 11 Tokens are digital assets earned by users through their participation in fantasy sports tournaments, completing achievements, or engaging in platform activities. These tokens are the primary currency of the platform, rewarding users for their strategic gameplay and participation.',
        image: '/assets/images/world-11-token-1.jpg',
    },
    {
        name: 'Blockchain-Powered',
        description: 'World 11 Tokens are powered by blockchain technology, which ensures both security and transparency. The use of blockchain guarantees that all transactions are fast, secure, and traceable, enhancing trust and reliability across the platform.',
        image: '/assets/images/world-11-token-2.jpg',
    },
    {
        name: 'How Tokens Can Be Used',
        description: 'World 11 Tokens can be used in several exciting ways. Users can spend tokens for in-app purchases, such as premium features, or use them to enter exclusive tournaments and premium leagues. Additionally, tokens can be traded for unique digital collectibles (NFTs) that offer special in-game perks and rewards, enhancing the gaming experience.',
        image: '/assets/images/world-11-token-3.png',
    },
    {
        name: 'Token Economy',
        description: 'The platform operates on a deflationary token model, meaning that over time, the supply of World 11 Tokens decreases, which increases their value. This creates an incentive for users to accumulate and use their tokens strategically. By participating more actively in tournaments and platform activities, users can grow their token holdings and benefit from their increasing value within the ecosystem.',
        image: '/assets/images/world-11-token-4.jpg',
    }
]

export const tournamentData = [
    {
        name: 'Live Tournaments',
        description: 'Get real-time updates on both global and local tournaments across all major sports, keeping you informed about ongoing matches and player performances as they happen.',
        image: '/assets/images/live-tournament.png',
    },
    {
        name: 'Leaderboard Integration',
        description: 'Track your progress and rank on leaderboards, both globally and regionally. See how you stack up against other fantasy players in each tournament and strive to climb the ranks.',
        image: '/assets/images/leaderboard2.jpg',
    },
    {
        name: 'Upcoming Tournaments',
        description: 'Stay up-to-date with upcoming global and local tournaments in real time. Plan your fantasy teams in advance and be ready when the next big match kicks off.',
        image: '/assets/images/upcoming-tournament.jpg',
    },
    {
        name: 'Prize Pools',
        description: 'Discover details about the prize pools for scheduled tournaments, helping you prepare your teams early and compete for rewards in top-tier competitions.',
        image: '/assets/images/prize-pool.jpg',
    },
]

export const blogData = [
    {
        title: "Sports News",
        description: "The platform will provide daily or weekly updates on major sports events, including match results, player injuries, and transfer news. ",
        imageUrl: "/assets/images/sports-news.png"
    },
    {
        title: "Fantasy Tips",
        description: "To enhance user experience, the website will offer expert analysis and strategies for selecting the best fantasy teams. ",
        imageUrl: "/assets/images/fantasy-sports.png"
    },
    {
        title: "Player Spotlights",
        description: "The platform will feature profiles of top-performing players across various sports, highlighting their recent form in real-life tournaments.",
        imageUrl: "/assets/images/player-spotlight.png"
    },
    {
        title: "Tech and Sports Innovation",
        description: "The website will share content related to the integration of blockchain technology in fantasy sports. ",
        imageUrl: "/assets/images/sport-innovation.png"
    },
    {
        title: "Match Previews",
        description: "Get detailed insights into upcoming matches, including predicted lineups, key players to watch, and tactical analysis.",
        imageUrl: "/assets/images/match-preview.jpg"
    },
    {
        title: "Game Reviews",
        description: "The platform will provide reviews of recent matches, breaking down key moments, strategies employed by teams, and performance highlights of notable players",
        imageUrl: "/assets/images/game-review.jpg"
    },
    {
        title: "Transfer Rumors",
        description: "Stay updated with the latest transfer rumors, potential deals, and confirmed signings across major leagues. ",
        imageUrl: "/assets/images/transfer-rumors.png"
    },
    {
        title: "Behind the Scenes",
        description: "The website will explore exclusive behind-the-scenes content, including player interviews, team preparations, and training footage. ",
        imageUrl: "/assets/images/behind-scene.jpg"
    }
];

export const designData = [
    {
        title: "Intuitive Navigation",
        description: "Users can easily browse between pages, find specific sports, and view tournament information.",
    },
    {
        title: "Interactive Graphics",
        description: "Use dynamic visuals such as player cards, real-time leaderboards, and interactive elements to enhance the user experience.",
    },
    {
        title: "Responsive Design",
        description: "Ensure that the website is optimized for both desktop and mobile use, providing seamless access across devices.",
    },
];


export const NavbarContext = createContext()
