import React, { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { tournamentData } from '../constant/config';


const Tournament = () => {
    const [currentTournament, setCurrentTournament] = (useState(tournamentData[0].name));

    const handleMouseEnter = (sport) => {
        setCurrentTournament(sport);
    };

    return (
        <>
            <div className='bg-[#FFDC33] lg:mt-36 lg:h-[70vh] lg:min-h-[80vh]'>
                <div className='grid lg:grid-cols-2 grid-cols-1 h-full'>
                    <div className="world11token-image-cross-cut relative h-full">
                        {tournamentData.map(
                            (tournament, index) =>
                                currentTournament === tournament.name && (
                                    <div className='relative h-full sm:min-h-[60vh] min-h-[35vh]' key={index}>
                                        <img
                                            src={tournament.image}
                                            alt={tournament.alt}
                                            className='w-full h-full object-cover'
                                        />
                                        <div className='absolute inset-0 flex items-end bg-black bg-opacity-50 ps-10 pe-16 md:pb-0 pb-3'>
                                            <p className='text-white sm:text-lg text-sm md:py-4 md:pr-0 pr-9'>{tournament.description}</p>
                                        </div>
                                    </div>
                                )
                        )}
                    </div>
                    <div className='flex items-center xl:mt-0 py-20'>
                        <div className='lg:px-16 px-10'>
                            <p className='font-medium text-[1.12rem] mb-10'># Tournaments</p>

                            {tournamentData.map((tournament, index) => (
                                <Fade direction='up' triggerOnce='true' key={index}>
                                    <div className='mt-7'>
                                        <p
                                            className={`md:text-4xl text-2xl font-medium cursor-pointer 
                                                ${currentTournament === tournament.name ? 'text-customPink' : ''} 
                                                hover:text-customPink`}
                                            onMouseEnter={() => handleMouseEnter(tournament.name)}
                                        >
                                            {tournament.name}
                                        </p>
                                    </div>
                                </Fade>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Tournament;
